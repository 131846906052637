<template>
<div>
  <Row>
    <Col span="24">
      <Form :model="formItem" :label-width="110" ref="AddForm">
        <FormItem label="类型" prop="permission_type" >
          <treeselect @select="onSelect" placeholder="权限类型" v-model="formItem.permission_type" :options="permission_type" style="width: 600px"/>
        </FormItem>

        <FormItem label="权限名称" prop="permission_name">
          <Input v-model="formItem.permission_name" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="权限简介" prop="permission_intro">
          <Input type="textarea" v-model="formItem.permission_intro" style="width: 600px"></Input>
        </FormItem>

        <FormItem label="所属" prop="permission_parent">
          <treeselect placeholder="选择该项所属的上一级" v-model="formItem.permission_parent" :options="permissions" style="width: 600px"/>
        </FormItem>

        <FormItem label="标识" prop="permission_mark">
          <Input v-model="formItem.permission_mark" style="width: 600px" placeholder="菜单的地址、按钮和api的标识，菜单地址以‘/’开头，按钮和api标识不能以‘/’开头"></Input>
        </FormItem>
        <FormItem label="是否左侧主菜单" prop="permission_main_menu" v-show="main_menu">
          <Checkbox :true-value="1" :false-value="0" size="large" v-model="formItem.permission_main_menu"></Checkbox>
        </FormItem>

        <FormItem>
          <Button size="large" type="primary" @click="submit()" :disabled="btnDisabled">确定</Button>
        </FormItem>

      </Form>
    </Col>
  </Row>
</div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import api from '../../api/setup';
export default {
  name: "AddPermission",
  components: { Treeselect },
  data: function(){
    return {
      formItem: {
        permission_name: '',
        permission_intro: '',
        permission_parent: 0,
        permission_type: '',
        permission_mark: '',
        permission_main_menu: 0
      },
      permissions: [
        {id: 0,label: '顶级',}
      ],
      permission_type: [
        {id: '', label: '请选择'},
        {id: 'api', label: 'api'},
        {id: 'menu', label: 'menu'},
        {id: 'button', label: 'button'},
      ],
      main_menu: false
    }
  },
  computed: {
    btnDisabled: function(){
      return this.formItem.permission_name === '' ||
          this.formItem.permission_mark === '' ||
          this.formItem.permission_type === '' ||
          typeof(this.formItem.permission_parent) == "undefined" ||
          typeof(this.formItem.permission_type) == "undefined"
    }
  },
  methods: {
    getList: function(type){
      this.$http.get(api.permissions, {params: {type: type}}).then(response => {
        if(response.data.success) {
          this.permissions = [{id: 0,label: '顶级',}];
          this.permissions.push(...response.data.res);
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    onSelect: function(node){
      console.log(node);
      if(node.id !== '') {
        this.main_menu = (node.id === 'menu');
        if(node.id === 'button') {
          this.getList('menu');
        } else {
          this.getList(node.id);
        }
      }
    },
    submit: function(){
      this.$http.post(api.addPermission, this.formItem).then(response => {
        if(response.data.success) {
          this.$refs.AddForm.resetFields();
          this.$Message.success('添加成功')
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    }
  },
  mounted() {
    this.$emit('lastPage', '/setup/permissions')
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>

</style>